import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { ApiPost } from '../helpers/axiosFunctions';
import { errorToast, successToast } from '../helpers/commonFunctions';

export default function Login() {
    const navigate = useNavigate()
    const [conditionCheck, setConditionCheck] = useState(false)
    const [tempData, setTempData] = useState({
        email: "",
        password: "",
    })
    const [loading, setLoading] = useState(false)

    const loginSuccess = (userData) => {
        localStorage.setItem("zero_kataa_user", JSON.stringify(userData))
        localStorage.removeItem("zero_kataa_email")
        navigate("/home")
    }

    const googleLoginBtn = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setLoading(true)
            await ApiPost('/user/google_login', { accessToken: tokenResponse?.access_token }, {}, false)
                .then((res) => {
                    loginSuccess(res?.data)
                    successToast("Google Login successfully")
                })
                .catch((error) => {
                    errorToast(error?.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        onError: error => errorToast(error),
        error_callback: error => errorToast(error?.message || "Google Login Failed"),
        scope: "email profile openid",
        flow: 'implicit',
    });

    const handleFacebookLoginSuccess = async (data) => {
        setLoading(true)
        await ApiPost('/user/facebook_login', { accessToken: data?.accessToken }, {}, false)
            .then((res) => {
                loginSuccess(res?.data)
                successToast("Facebook Login successfully")
            })
            .catch((error) => {
                errorToast(error?.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleCustomLogin = async () => {
        const body = {
            email: tempData.email,
            password: tempData.password
        }
        setLoading(true)
        await ApiPost('/user/login', body, {}, false)
            .then((res) => {
                successToast("Email Login Successfully");
                loginSuccess(res?.data)
            })
            .catch((error) => {
                errorToast(error?.message)
                if (error?.status === 406) {
                    localStorage.setItem('zero_kataa_email', tempData.email)
                    navigate("/auth/otp-verification")
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChange = ({ target }) => {
        setTempData({ ...tempData, [target.name]: target.value })
    }

    useEffect(() => {
        if (localStorage.getItem('zero_kataa_user')) {
            navigate("/home")
        } else {
            setConditionCheck(true)
        }
    }, [])

    if (!conditionCheck) {
        return <div className='w-full flex flex-col flex-center text-4xl font-bold text-textYellow'>LOADING...</div>
    }

    return (
        <>
            <div className='text-primaryPurple w-full flex flex-col flex-center px-10 text-center'>
                <div className="heading text-textYellow">
                    <h1 className='text-4xl font-bold'>WELCOME</h1>
                    <h4 className='text-lg font-semibold'>It's great to see you again! Log in now</h4>
                </div>
                <div className='flex flex-col gap-4 w-full mt-5'>
                    <input
                        type="text"
                        className='p-2.5 rounded-lg w-full'
                        placeholder='Enter Email Address'
                        name='email'
                        value={tempData?.email}
                        onChange={handleChange}
                    />
                    <input
                        type="password"
                        className='p-2.5 rounded-lg w-full'
                        placeholder='Enter Password'
                        name='password'
                        value={tempData?.password}
                        onChange={handleChange}
                    />
                    <button className='px-4 mt-2 theme-btn' onClick={handleCustomLogin} disabled={loading}>{loading ? "Loading..." : "LOGIN"}</button>
                </div>
                <button
                    onClick={() => googleLoginBtn()}
                    className="theme-btn px-3 mt-10 w-full flex items-center justify-center gap-5"
                    disabled={loading}
                >
                    {loading ? "Loading..." : <><img src="/media/images/google_logo.png" className='h-10 w-auto' alt="Google logo" />CONNECT TO GOOGLE</>}
                </button>
                <FacebookLogin
                    appId="589651403541328"
                    onSuccess={handleFacebookLoginSuccess}
                    onFail={() => errorToast('Facebook Login Failed!')}
                    // onProfileSuccess={(data) => console.log('facebookData', data)}
                    className="theme-btn px-3 mt-5 w-full flex items-center justify-center gap-3"
                    disabled={loading}
                >
                    {loading ? "Loading..." : <><img src="/media/images/facebook_logo.png" className='h-10 w-auto' alt="Google logo" />CONNECT TO FACEBOOK</>}
                </FacebookLogin>
                <div className='text-white mt-3'>Don't have an account? <Link to="/auth/signup" className='text-textYellow font-bold'>Sign Up</Link></div>
                <div className='text-white text-sm mt-7'>By Continuing I agree to Terms of conditions & Privacy Policy</div>
            </div>
        </>
    )
}
