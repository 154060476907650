import { Dialog, DialogPanel } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { useSelector } from 'react-redux'
import { ApiPost } from '../../helpers/axiosFunctions'
import { errorToast } from '../../helpers/commonFunctions'

export default function WinnerModal({ isOpen, setIsOpen }) {
    const navigate = useNavigate()
    const { userData } = useSelector(state => state.profileData)

    const handlePlay = async () => {
        await ApiPost("/user/tournament", {
            player1: userData?._id,
            player2: null,
            amount: 100
        })
            .then(() => {
                navigate("/tournament-battle")
            })
            .catch((error) => {
                errorToast(error?.message)
            })
    }

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={() => { setIsOpen(false) }}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur-xl duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <div className='flex justify-end mb-3'>
                                <SVG src='/media/svg/close_modal.svg' className='cursor-pointer' onClick={() => { setIsOpen(false) }} />
                            </div>
                            <h1 className="text-4xl text-center font-medium text-white">
                                Private Tournament
                            </h1>
                            <div className=' bg-[url(/public/media/images/selectTournamentBG.png)] bg-contain bg-no bg-no-repeat h-[300px] mt-7 w-[280px] mx-auto text-center'>
                                <h1 className='text-5xl text-primaryPurple pt-9 mb-[60px]'>WIN</h1>
                                <h3 className='text-3xl text-white mb-10 text-shadow'>Price: ₹1,500</h3>
                                <h3 className='text-4xl text-primaryPurple'>Entry: ₹100</h3>
                            </div>
                            <div className='text-white text-center mt-5'>
                                <div>The Payment is split in case of a tie.</div>
                                <div>Maintain a good internet connection.</div>
                                <div>An unstable connection can lead to disqualification.</div>
                            </div>
                            <div className='flex flex-center mt-10'>
                                <button className='theme-btn mx-auto px-10' onClick={handlePlay}>PLAY</button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}