export default function Error404() {
    return (
        <div className=' w-full flex flex-col flex-center px-10'>
            <div className="heading text-center">
                <h4 className='text-8xl font-semibold text-textYellow my-2'>404</h4>
                <h1 className='text-3xl font-bold text-white'>Page Not Fount</h1>
            </div>
            <a className='theme-btn px-7 mt-10' href='/'>Got to Home Page</a>
        </div>
    )
}
