import { useEffect, useState } from "react";
import { calculateWinner } from "../../helpers/boardFunctions";
import { useNavigate } from "react-router-dom";
import { infoToast } from "../../helpers/commonFunctions";
import { SocketI } from "../../App";

export default function ThreeBoardHuman() {
    const navigate = useNavigate();
    const [squares, setSquares] = useState(Array(9).fill(null));
    const [xIsNext, setXIsNext] = useState(true);
    const [player, setPlayer] = useState("X");
    const [roomId, setRoomId] = useState("");

    const winner = calculateWinner(squares);
    const isDraw = squares.every((square) => square !== null) && !winner;

    // Handle player click
    const handleClick = (index) => {
        if (winner || squares[index]) return;
        if (!xIsNext && player === "X") return;
        if (xIsNext && player === "O") return;

        const newSquares = [...squares];
        newSquares[index] = player;
        setSquares(newSquares);

        // Emit move to other player via Socket.io
        SocketI.emit("playerMove", { squares: newSquares, roomId });
        setXIsNext(!xIsNext); // Switch turns
    };

    // Handle move received from the server
    useEffect(() => {
        SocketI.on("moveMade", ({ squares }) => {
            setSquares(squares);
            setXIsNext(!xIsNext); // Switch turns
        });

        // Handle game reset event from server
        SocketI.on("resetGame", () => {
            resetGame();
        });

        // Cleanup on component unmount
        return () => {
            SocketI.off("moveMade");
            SocketI.off("resetGame");
        };
    }, [xIsNext]);

    // Reset the game
    const resetGame = () => {
        setSquares(Array(9).fill(null));
        setXIsNext(true);

        // Notify the server to reset the game
        SocketI.emit("resetGame", { roomId });
    };

    useEffect(() => {
        // Join a specific game room when the component loads
        const room = prompt("Enter Room ID to join: ");
        setRoomId(room);
        SocketI.emit("joinRoom", { roomId: room, player: player });

        SocketI.on("assignPlayer", (playerType) => {
            setPlayer(playerType); // Server assigns "X" or "O"
        });

        // Redirect to home when game ends
        if (winner || isDraw) {
            infoToast("Redirecting to Home...");
            setTimeout(() => {
                navigate("/home");
            }, 3000);
        }

        return () => {
            SocketI.off("assignPlayer");
        };
    }, [winner, isDraw]);

    return (
        <div className="tic-tac-toe">
            {xIsNext && (!winner && !isDraw) && <div className='text-2xl text-textYellow mt-7'>Player X's Turn</div>}
            {!xIsNext && (!winner && !isDraw) && <div className='text-2xl text-textYellow mt-7'>Player O's Turn</div>}

            <div className="board outline outline-[15px] border-[10px] border-primaryPurple outline-black shadow-inner shadow-black text-4xl rounded-sm">
                {squares.map((square, index) => (
                    <button
                        key={index}
                        className="square odd:bg-[#F6B349] even:bg-[#FB577A]"
                        onClick={() => handleClick(index)}
                    >
                        {(square && square === "X") && <img src="/media/svg/X_in_board.svg" width={50} height={50} />}
                        {(square && square === "O") && <img src="/media/svg/O_in_board.svg" width={50} height={50} />}
                    </button>
                ))}
            </div>

            {winner && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-green-500 left-1/2 -translate-x-1/2 -translate-y-1/2">Winner: {winner}</p>}
            {isDraw && !winner && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-textYellow left-1/2 -translate-x-1/2 -translate-y-1/2">It's a Draw!</p>}

            <button onClick={resetGame} className="mt-10 p-4 bg-yellow-500 text-white rounded">Reset Game</button>

            <div className="flex justify-center gap-20 mt-14">
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${(xIsNext && (!winner && !isDraw)) && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        Player X
                    </div>
                </div>
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${(!xIsNext && (!winner && !isDraw)) && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://static.vecteezy.com/system/resources/thumbnails/025/271/424/small/robot-chatbot-generative-ai-free-png.png"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        Player O
                    </div>
                </div>
            </div>
        </div>
    );
};