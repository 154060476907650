import { GiDiamondTrophy } from "react-icons/gi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { RiNftFill } from "react-icons/ri";
import { GoHomeFill } from "react-icons/go";
import { useEffect, useState } from "react";
import { ApiGet } from "../helpers/axiosFunctions";
import { errorToast } from "../helpers/commonFunctions";
import { useDispatch } from "react-redux";
import { changeProfile_UserData } from "../Redux/slices/profileSlice";
import { Link, useLocation } from "react-router-dom";

export default function NavBar() {
    const dispatch = useDispatch()
    const { pathname } = useLocation();
    const [activeTab, setActiveTab] = useState("Home")

    const fetchUserData = async () => {
        await ApiGet("/user/profile")
            .then((result) => {
                dispatch(changeProfile_UserData(result?.data))
            })
            .catch((error) => {
                errorToast(error?.message)
            })
    }

    useEffect(() => {
        fetchUserData();
    }, [])

    useEffect(() => {
        if (pathname === "/wallet") {
            setActiveTab("Wallet")
        } else {
            setActiveTab("Home")
        }
    }, [pathname]);

    return (
        <div className="w-full bg-primaryPurple border-t border-textYellow shadow-sm">
            <div className="flex justify-around items-center h-20">
                <Link to="/home" className={`hover:text-textYellow ${activeTab === "Home" ? "text-textYellow" : "text-white"} flex flex-col items-center gap-1`}>
                    <GoHomeFill size={25} />
                    <span>Home</span>
                </Link>
                <button className={`hover:text-textYellow ${activeTab === "Market" ? "text-textYellow" : "text-white"} flex flex-col items-center gap-1`}>
                    <GiDiamondTrophy size={25} />
                    <span>Market</span>
                </button>
                <Link to="/wallet" className={`hover:text-textYellow ${activeTab === "Wallet" ? "text-textYellow" : "text-white"} flex flex-col items-center gap-1`} >
                    <MdOutlineAccountBalanceWallet size={25} />
                    <span>Wallet</span>
                </Link>
                <button className={`hover:text-textYellow ${activeTab === "NFT" ? "text-textYellow" : "text-white"} flex flex-col items-center gap-1`}>
                    <RiNftFill size={25} />
                    <span>NFT</span>
                </button>
            </div>
        </div>
    )
}
