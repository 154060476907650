export const boardSelectionJson = [
    {
        level: "3x3",
        image: "/media/images/3X3Logo.png",
    },
    {
        level: "6x6",
        image: "/media/images/6X6Logo.png",
    },
    {
        level: "9x9",
        image: "/media/images/6X6Logo.png",
    },
    {
        level: "12x12",
        image: "/media/images/6X6Logo.png",
    },
]

export const TransactionEntryType = {
    1: "Credited",
    2: "Debited",
    3: "Withdraw",
}