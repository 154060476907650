import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { ApiPost } from '../helpers/axiosFunctions';
import { errorToast, successToast } from '../helpers/commonFunctions';

export default function SignUp() {
    const navigate = useNavigate()
    const [tempData, setTempData] = useState({
        name: "",
        email: "",
        password: "",
        referCode: ""
    })
    const [loading, setLoading] = useState(false)

    const loginSuccess = (userData) => {
        localStorage.setItem("zero_kataa_user", JSON.stringify(userData))
        navigate("/home")
    }

    const googleLoginBtn = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setLoading(true)
            await ApiPost('/user/google_login', { accessToken: tokenResponse?.access_token }, {}, false)
                .then((res) => {
                    loginSuccess(res?.data)
                    successToast("Google Login successfully")
                })
                .catch((error) => {
                    errorToast(error?.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        onError: error => errorToast(error),
        error_callback: error => errorToast(error?.message || "Google Login Failed"),
        scope: "email profile openid",
        flow: 'implicit',
    });

    const handleFacebookLoginSuccess = async (data) => {
        setLoading(true)
        await ApiPost('/user/facebook_login', { accessToken: data?.accessToken }, {}, false)
            .then((res) => {
                loginSuccess(res?.data)
                successToast("Facebook Login successfully")
            })
            .catch((error) => {
                errorToast(error?.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleCustomSignUp = async () => {
        const body = {
            name: tempData.name,
            email: tempData.email,
            password: tempData.password,
        }
        if (tempData.referCode) {
            body.referCode = tempData.referCode
        }
        setLoading(true)
        await ApiPost('/user/signup', body, {}, false)
            .then(() => {
                successToast("Email Register Successfully");
                navigate("/auth/login")
            })
            .catch((error) => {
                errorToast(error?.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChange = ({ target }) => {
        setTempData({ ...tempData, [target.name]: target.value })
    }

    return (
        <>
            <div className='text-primaryPurple w-full flex flex-col flex-center px-10 text-center'>
                <div className="heading text-textYellow">
                    <h1 className='text-4xl font-bold'>SignUp</h1>
                </div>
                <div className='flex flex-col gap-4 w-full mt-5'>
                    <input
                        type="text"
                        className='p-2.5 rounded-lg w-full text-lg'
                        placeholder='Enter Full Name'
                        name='name'
                        autoComplete='off'
                        autoCorrect='off'
                        value={tempData?.name}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        className='p-2.5 rounded-lg w-full text-lg'
                        placeholder='Enter Email Address'
                        name='email'
                        autoComplete='off'
                        autoCorrect='off'
                        value={tempData?.email}
                        onChange={handleChange}
                    />
                    <input
                        type="password"
                        className='p-2.5 rounded-lg w-full text-lg'
                        placeholder='Enter Password'
                        name='password'
                        autoComplete='off'
                        autoCorrect='off'
                        value={tempData?.password}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        className='p-2.5 rounded-lg w-full text-lg'
                        placeholder='Enter Refer Code (optional)'
                        name='referCode'
                        autoComplete='off'
                        autoCorrect='off'
                        value={tempData?.referCode}
                        onChange={handleChange}
                    />
                    <button className='px-4 mt-2 theme-btn' onClick={handleCustomSignUp} disabled={loading}>{loading ? "Loading..." : "SIGN UP"}</button>
                </div>
                <button
                    onClick={() => googleLoginBtn()}
                    className="theme-btn px-3 mt-10 w-full flex items-center justify-center gap-3"
                    disabled={loading}
                >
                    {loading ? "Loading..." : <><img src="/media/images/google_logo.png" className='h-10 w-auto' alt="Google logo" />CONNECT TO GOOGLE</>}
                </button>
                <FacebookLogin
                    appId="589651403541328"
                    onSuccess={handleFacebookLoginSuccess}
                    onFail={() => errorToast('Facebook Login Failed!')}
                    className="theme-btn px-3 mt-5 w-full flex items-center justify-center gap-3"
                    disabled={loading}
                >
                    {loading ? "Loading..." : <><img src="/media/images/facebook_logo.png" className='h-10 w-auto' alt="Google logo" />CONNECT TO FACEBOOK</>}
                </FacebookLogin>
                <div className='text-white mt-3'>Already have an account? <Link to="/auth/login" className='text-textYellow font-bold'>Login</Link></div>
                <div className='text-white text-sm mt-7'>By Continuing I agree to Terms of conditions & Privacy Policy</div>
            </div>
        </>
    )
}
