import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './custom.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { Provider } from 'react-redux';
import { store } from './Redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store} >
      <App />
    </Provider>
  </>
);
