import toast from "react-hot-toast"

export const errorToast = (text) => toast.error(text || "Something went wrong", {
    style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
    },
})

export const successToast = (text) => toast.success(text || "Success", {
    style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
    },
})

export const infoToast = (text) => toast(text || "Information", {
    icon: 'ℹ',
    style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
    },
})
export const Logout = () => {
    localStorage.removeItem("zero_kataa_user")
    window.location.reload()
}