import { Dialog, DialogPanel } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HiMiniCheckBadge } from "react-icons/hi2";
import { GiCancel } from "react-icons/gi";

export default function PaymentResponseModal() {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search).get("payment_status")

    const handleClose = () => {
        navigate("/home")
        setIsOpen(false)
    }

    useEffect(() => {
        if (params) {
            setIsOpen(true)
            setTimeout(() => {
                handleClose()
            }, 3000);
        }
    }, [params])

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={handleClose}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur-xl duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            {params === "success" && <HiMiniCheckBadge className={`${params === "success" ? "text-green-500" : "text-red-500"} mx-auto text-9xl animate-bounce my-7`} />}
                            {params === "failure" && <GiCancel className={`${params === "success" ? "text-green-500" : "text-red-500"} mx-auto text-9xl animate-pulse my-7`} />}
                            <h1 className="text-4xl text-center font-medium text-white text-shadow">
                                {params === "success" && "Payment Successfully"}
                                {params === "failure" && "Payment Failed"}
                            </h1>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}