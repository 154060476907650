import React, { useState } from 'react'
import { MdHeadsetOff } from 'react-icons/md';
import SVG from 'react-inlinesvg';

export const audioElement = document.getElementById('background-audio');
export default function AudioIcon() {
    const [isMuted, setIsMuted] = useState(false);

    const handleMuteUnmute = () => {
        if (audioElement) {
            audioElement.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    };

    return (
        <>
            <div className="absolute top-7 right-7 text-white hover:text-textYellow duration-200 border border-textYellow rounded-lg p-1.5 cursor-pointer" onClick={handleMuteUnmute}>
                {isMuted ?
                    <MdHeadsetOff className='text-[21px]' /> :
                    <SVG src="/media/svg/audio.svg" />}
            </div>
        </>
    )
}
