import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoNotificationsOutline } from "react-icons/io5";
import { BsCurrencyRupee } from "react-icons/bs";
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import AddMoneyModal from './Modal/AddMoneyModal';
import WalletShowModal from './Modal/WalletShowModal';

export default function TopNavbar({ rules = false }) {
    const { userData } = useSelector(state => state.profileData)
    const [addMoneyModal, setAddMoneyModal] = useState(false)
    const [walletShowModal, setWalletShowModal] = useState(false)

    const addMoneyBtn = () => {
        setAddMoneyModal(true)
    }

    return (<>
        <div className='flex justify-between items-center pt-5 px-7'>
            <div>
                {rules ?
                    <Link className='text-2xl text-white' to="/rules" >Rules</Link> :
                    <Link to="/home"><SVG src="/media/svg/backArrow.svg" /></Link>
                }
            </div>
            <div className='flex gap-4 items-center'>
                <div className='text-white cursor-pointer'>
                    <IoNotificationsOutline size={30} />
                </div>
                <div className='bg-white text-primaryPurple rounded-full pt-1 min-w-24 px-2 text-center cursor-pointer' title='Bonus Wallet' onClick={() => setWalletShowModal(true)}>
                    ₹{Number(userData?.bonusBalance || 0).toFixed(2)}
                </div>
                <div className='relative bg-white rounded-full flex flex-center w-10 h-10 text-primaryPurple cursor-pointer' onClick={addMoneyBtn}>
                    <BsCurrencyRupee size={25} className='' />
                    <div className='absolute -right-1 -top-2 text-3xl' style={{ textShadow: "1px 0px 0px white" }}>
                        +
                    </div>
                </div>
            </div>
        </div>
        <AddMoneyModal
            isOpen={addMoneyModal}
            setIsOpen={setAddMoneyModal}
        />
        <WalletShowModal
            isOpen={walletShowModal}
            setIsOpen={setWalletShowModal}
        />
    </>)
}
