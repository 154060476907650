import React, { useEffect, useState } from 'react'
import AudioIcon from '../components/AudioIcon'
import { RxCross2 } from 'react-icons/rx'
import { FiPause } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AIThreeTournamentBoard from '../components/battle/AIThreeTournamentBoard'
import { ApiGet } from '../helpers/axiosFunctions'
import { errorToast } from '../helpers/commonFunctions'
import { changeBoard_tournamentData } from '../Redux/slices/boardSlice'

export default function TournamentBattlePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { battleType } = useSelector(state => state.boardData)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        navigate("/home")
    }

    const fetchTournamentData = async () => {
        setLoading(true)
        await ApiGet("/user/tournament")
            .then((result) => {
                dispatch(changeBoard_tournamentData(result?.data))
            })
            .catch((error) => {
                errorToast(error?.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchTournamentData()
    }, [])

    return (
        <>
            <AudioIcon />
            <div className="absolute top-7 left-7 text-white border border-textYellow rounded-lg p-1 cursor-pointer" onClick={handleClose}>
                <RxCross2 size={25} />
            </div>
            <div className="absolute top-20 left-7 text-white border border-textYellow rounded-lg p-1.5 cursor-pointer">
                <FiPause size={23} />
            </div>
            <div className="flex flex-col flex-1 flex-center text-center">
                <div id="board" className='mt-7'>
                    {loading ?
                        <div className='text-5xl text-textYellow'>Loading...</div> :
                        battleType === 'AI' && <AIThreeTournamentBoard />
                    }
                </div>
            </div>
        </>
    )
}
