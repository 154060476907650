import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../components/NavBar'

export default function Layout() {
    return (
        <div className='bg-[radial-gradient(#2d2d2d,black)] font-daydreamer'>
            <div className="max-w-md min-h-screen mx-auto flex flex-col relative">
                <div className='flex-1 flex bg-[url(/public/media/images/background_image.png)] bg-cover bg-top'>
                    <Outlet />
                </div>
                <div className="sticky w-full bottom-0">
                    <NavBar />
                </div>
            </div>
        </div>
    )
}
