import { Dialog, DialogPanel } from '@headlessui/react'
import SVG from 'react-inlinesvg'

export default function WinnerModal({ isOpen, setIsOpen, winnerData }) {
    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={() => { setIsOpen(false) }}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur-xl duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <div className='flex justify-end mb-3'>
                                <SVG src='/media/svg/close_modal.svg' className='cursor-pointer' onClick={() => { setIsOpen(false) }} />
                            </div>
                            <div className=' bg-[url(/public/media/images/selectTournamentBG.png)] bg-contain bg-no bg-no-repeat h-[300px] mt-7 w-[280px] mx-auto text-center'>
                                <h1 className='text-4xl text-primaryPurple pt-9 mb-[65px]'>{winnerData?.isDraw ? "DEFEAT" : "YOU WON"}</h1>
                                <h3 className='text-5xl text-green-100 mb-10'>{!winnerData?.isDraw && "₹1500"}</h3>
                                <h3 className='text-4xl text-primaryPurple'>{winnerData?.finalWinnerName}</h3>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}