import { Dialog, DialogPanel } from '@headlessui/react'
import { useSelector } from 'react-redux'
import SVG from 'react-inlinesvg'

export default function WalletShowModal({ isOpen, setIsOpen }) {
    const { userData } = useSelector(state => state.profileData)

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={handleClose}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur-xl duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <div className='flex justify-end'>
                                <SVG src='/media/svg/close_modal.svg' className='cursor-pointer' onClick={handleClose} />
                            </div>
                            <h1 className="text-4xl text-center font-medium text-textYellow">
                                Main Wallet
                            </h1>
                            <div className=' bg-[url(/public/media/images/signupBonus_background.png)] bg-cover bg-no bg-no-repeat h-[150px] mt-1 w-[280px] mx-auto text-center'>
                                <h1 className='text-3xl text-white pt-[65px]'>₹{userData?.balance}</h1>
                            </div>
                            <h1 className="text-4xl text-center font-medium text-textYellow mt-7">
                                Bonus Wallet
                            </h1>
                            <div className=' bg-[url(/public/media/images/signupBonus_background.png)] bg-cover bg-no bg-no-repeat h-[150px] mt-1 w-[280px] mx-auto text-center'>
                                <h1 className='text-3xl text-white pt-[65px]'>₹{userData?.bonusBalance}</h1>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}