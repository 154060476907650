import { createSlice } from "@reduxjs/toolkit";

/**
 * @typedef {Object} GameSettings
 * @property {string} difficulty - "Easy", "Medium", "Hard".
 * @property {string} versus - "AI", "Human".
 */
const initialState = {
    difficulty: "Easy",
    versus: "AI",
    battleType: "AI",
    matchNo: 1,
    tournamentData: {},
};

const boardSlice = createSlice({
    name: "boardData",
    initialState,
    reducers: {
        changeBoard_difficulty(state, { payload }) {
            state.difficulty = payload;
        },
        changeBoard_versus(state, { payload }) {
            state.versus = payload;
        },
        changeBoard_battleType(state, { payload }) {
            state.battleType = payload;
        },
        changeBoard_matchNo(state, { payload }) {
            state.matchNo = payload;
        },
        changeBoard_tournamentData(state, { payload }) {
            state.tournamentData = payload;
        },
    },
});

export const {
    changeBoard_difficulty,
    changeBoard_versus,
    changeBoard_battleType,
    changeBoard_matchNo,
    changeBoard_tournamentData
} = boardSlice.actions;

export default boardSlice.reducer;