import { Dialog, DialogPanel } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import SVG from 'react-inlinesvg'
import { ApiPut } from '../../helpers/axiosFunctions'
import { errorToast } from '../../helpers/commonFunctions'
import { changeProfile_UserData } from '../../Redux/slices/profileSlice'

export default function BonusSignUpModal({ isOpen, setIsOpen }) {
    const { userData } = useSelector(state => state.profileData)
    const dispatch = useDispatch()

    const handleClose = () => {
        setIsOpen(false)
        ApiPut("/user/profile", { isFirstSignup: false })
            .then((res) => {
                dispatch(changeProfile_UserData(res?.data))
            })
            .catch((error) => {
                errorToast(error?.message)
            })
    }

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={handleClose}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur-xl duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <div className='flex justify-end mb-3'>
                                <SVG src='/media/svg/close_modal.svg' className='cursor-pointer' onClick={handleClose} />
                            </div>
                            <h1 className="text-4xl text-center font-medium text-textYellow">
                                CONGRATULATIONS SIGN UP BONUS
                            </h1>
                            <div className=' bg-[url(/public/media/images/signupBonus_background.png)] bg-contain bg-no bg-no-repeat h-[210px] mt-7 w-[280px] mx-auto text-center'>
                                <h1 className='text-5xl text-white pt-[60px] mb-[60px]'>₹{userData?.bonusBalance}</h1>
                            </div>
                            <div className='text-white text-center mt-5'>
                                <div>Refer and earn. code <span className='text-textYellow text-2xl drop-shadow'>{userData?.referCode || ""}</span></div>
                            </div>
                            <div className='flex flex-center mt-10'>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}