import React, { useEffect, useState } from 'react'
import TopNavbar from '../components/TopNavbar'
import { ApiGet } from '../helpers/axiosFunctions'
import { errorToast } from '../helpers/commonFunctions'
import { SocketI } from '../App'
import { useSelector } from 'react-redux'

export default function PlayerSearchingPage() {
    const [roomData, setRoomData] = useState({})
    const { userData } = useSelector(state => state.profileData)

    const fetchRoomData = async () => {
        await ApiGet("/user/getRoom/" + userData?.uniqueId)
            .then((result) => {
                setRoomData(result?.data)
            })
            .catch((error) => {
                errorToast(error?.message)
            })
    }

    useEffect(() => {
        if (userData) {
            fetchRoomData()
            SocketI.on("joinRoom", () => {
                console.log("Received 'joinRoom' event from the server");
                fetchRoomData();
            });

            // Cleanup the SocketI event listener on component unmount
            return () => {
                SocketI.off("joinRoom");
            };
        }
    }, [userData])

    return (
        <>
            <div className='w-full'>
                <TopNavbar />
                <div className='flex flex-1 flex-col flex-center h-full pb-16 px-7 text-center'>
                    <h1 className='text-3xl text-textYellow'>Looking for an Opponent...</h1>
                    <desc className='text-white mt-4'>Please wait while we find someone to test <br /> your skills.</desc>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16 mt-7'>
                        <img
                            src={roomData?.senderData?.image || "https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"}
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <h3 className='text-2xl text-white mt-3'>{roomData?.senderData?.name}</h3>
                    <img
                        src='/media/images/VS_line.png'
                        className='my-10'
                    />
                    {roomData?.receiverData ?
                        <div className='bg-textYellow rounded-full flex flex-center h-16 w-16 text-white text-5xl circle-ripple'>
                            <img
                                src={roomData?.receiverData?.image || "https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"}
                                alt="receiver avatar"
                                className='rounded-full h-12 w-12 object-cover'
                            />
                        </div>
                        :
                        <div className='bg-textYellow rounded-full flex flex-center h-16 w-16 text-white text-5xl circle-ripple'>
                            ?
                        </div>
                    }
                    <h3 className='text-2xl text-white mt-3'>{roomData?.receiverData?.name || "???"}</h3>
                    <button className='theme-btn px-10 mt-10'>CANCEL</button>
                </div>
            </div>
        </>
    )
}
