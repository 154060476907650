import { configureStore } from "@reduxjs/toolkit";
import boardSlice from "./slices/boardSlice";
import profileSlice from "./slices/profileSlice";

// Configure store with persisted reducer
export const store = configureStore({
    reducer: {
        profileData: profileSlice,
        boardData: boardSlice
    }
});