import { useEffect, useState } from "react";
import { calculateWinner, hardAI } from "../../helpers/boardFunctions";
import { errorToast, infoToast, successToast } from "../../helpers/commonFunctions";
import { ApiGet, ApiPut } from "../../helpers/axiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeProfile_UserData } from "../../Redux/slices/profileSlice";
import WinnerModal from "../Modal/WinnerModal";

export default function AIThreeTournamentBoard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { tournamentData } = useSelector(state => state.boardData)
    const [squares, setSquares] = useState(Array(9).fill(null));
    const [xIsNext, setXIsNext] = useState(true);
    const [winnerModal, setWinnerModal] = useState(false)
    const [winnerData, setWinnerData] = useState()
    const [gameOver, setGameOver] = useState(false);
    const aiPlayer = "O";
    const humanPlayer = "X";

    const [gameResults, setGameResults] = useState({
        totalGames: 3,
        currentGame: 1,
        humanWins: 0,
        aiWins: 0,
        draws: 0,
    });

    const winner = calculateWinner(squares);
    const isDraw = squares.every((square) => square !== null) && !winner;

    const handleClick = (index) => {
        if (winner || squares[index]) return;
        if (!xIsNext) return;

        const newSquares = [...squares];
        newSquares[index] = xIsNext ? "X" : "O";
        setSquares(newSquares);
        setXIsNext(() => !xIsNext);

        if (xIsNext) {
            setTimeout(() => makeAIMove(newSquares), 3000); // AI moves after human
        }
    };

    const makeAIMove = (currentSquares) => {
        let move = hardAI(currentSquares, true, aiPlayer, humanPlayer);

        if (move !== undefined) {
            const newSquares = [...currentSquares];
            newSquares[move] = aiPlayer;
            setSquares(newSquares);
            setXIsNext(true);
        }
    };

    const resetGame = () => {
        setGameOver(false);
        setSquares(Array(9).fill(null));
        setXIsNext(true);
    };

    const handleEndOfGame = async () => {
        const updatedResults = { ...gameResults };
        let statusType = 'draw'
        let winId = null;
        if (winner === humanPlayer) {
            updatedResults.humanWins += 1;
            statusType = "win"
            winId = tournamentData.player1;
        } else if (winner === aiPlayer) {
            statusType = "draw"
            updatedResults.aiWins += 1;
        } else if (isDraw) {
            updatedResults.draws += 1;
            statusType = 'draw'
        }
        const response = await ApiPut("/user/tournament", {
            id: tournamentData?._id,
            matches: [{
                match: updatedResults.currentGame,
                winId,
                status: statusType
            }]
        })
            .then((result) => {
                if (result?.data?.isDraw) {
                    setWinnerData(result?.data)
                    setWinnerModal(true)
                    ApiGet("/user/profile")
                        .then((result) => {
                            dispatch(changeProfile_UserData(result?.data))
                        })
                        .catch((error) => {
                            errorToast(error?.message)
                        })
                    setTimeout(() => {
                        setWinnerModal(false)
                        navigate("/home")
                    }, 3000);
                    return false;
                }
                if (result?.data.hasOwnProperty('finalWinnerId')) {
                    setWinnerData(result?.data)
                    setWinnerModal(true)
                    ApiGet("/user/profile")
                        .then((result) => {
                            dispatch(changeProfile_UserData(result?.data))
                        })
                        .catch((error) => {
                            errorToast(error?.message)
                        })
                    setTimeout(() => {
                        setWinnerModal(false)
                        navigate("/home")
                    }, 3000);
                    return false;
                }
                return true;
            })
            .catch((error) => {
                errorToast(error?.message)
                return false;
            })
        if (!response) { return; }
        if (updatedResults.currentGame <= updatedResults.totalGames) {
            updatedResults.currentGame += 1;
        }
        setGameResults(updatedResults);

        // If it's the 3rd game, run your custom logic
        if (updatedResults.currentGame > updatedResults.totalGames) {
            customThirdGameLogic();
        } else {
            // Start the next match
            resetGame();
        }
    };

    const customThirdGameLogic = () => {
        if (gameResults.humanWins > gameResults.aiWins) {
            successToast("You won the tournament!");
        } else if (gameResults.aiWins > gameResults.humanWins) {
            infoToast("AI won the tournament!");
        } else {
            infoToast("The tournament ended in a draw!");
        }
    };

    useEffect(() => {
        if ((winner || isDraw) && !gameOver) {
            setGameOver(true);
            setTimeout(() => handleEndOfGame(), 3000);
        }
    }, [winner, isDraw, gameOver]);
    useEffect(() => {
        if (tournamentData) {
            if (tournamentData?.matches?.length >= 3) {
                errorToast("Tournament Already Completed")
                navigate("/home")
            }
            setGameResults({
                ...gameResults,
                currentGame: (tournamentData?.matches?.length + 1) || 1
            })
        }
    }, [tournamentData]);

    return (<>
        <div className="tic-tac-toe">
            <div className="text-textYellow text-2xl mb-3">Match {gameResults.currentGame} of {gameResults.totalGames}</div>
            <div className='text-white'>Timer 10 Min <br /> 00.00.08</div>
            {xIsNext && (!winner && !isDraw) && <>
                <div className='text-2xl text-textYellow mt-7'>Your Move</div>
                <div className='border border-textYellow bg-primaryPurple text-white/75 rounded-full pt-1 px-3 w-fit mx-auto mb-10'>X X X</div>
            </>}
            {!xIsNext && (!winner && !isDraw) && <>
                <div className='text-2xl text-textYellow mt-7'>{tournamentData?.fakeUserData?.name || "Iron Man"} Move</div>
                <div className='border border-textYellow bg-primaryPurple text-white/75 rounded-full pt-1 px-3 w-fit mx-auto mb-10'>O O O</div>
            </>}

            <div className="board !inline-grid outline outline-[15px] border-[10px] border-primaryPurple outline-black shadow-inner shadow-black text-4xl rounded-sm" style={{ gridTemplateColumns: "repeat(3, 70px)" }}>
                {squares.map((square, index) => (
                    <button
                        key={index}
                        className="square !h-[70px] !w-[70px] odd:bg-[#F6B349] even:bg-[#FB577A]"
                        onClick={() => handleClick(index)}
                    >
                        {(square && square === "X") && <img src="/media/svg/X_in_board.svg" width={50} height={50} ult="kataa" />}
                        {(square && square === "O") && <img src="/media/svg/O_in_board.svg" width={50} height={50} ult="zero" />}
                    </button>
                ))}
            </div>
            {winner === humanPlayer && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-green-500 left-1/2 -translate-x-1/2 -translate-y-1/2">Winner: {winner}</p>}
            {isDraw && !winner && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-textYellow left-1/2 -translate-x-1/2 -translate-y-1/2">It's a Draw!</p>}
            <div className="flex justify-center gap-20 mt-14">
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${(xIsNext && (!winner && !isDraw)) && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        You - X
                    </div>
                </div>
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${(!xIsNext && (!winner && !isDraw)) && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        {tournamentData?.fakeUserData?.name || "Iron Man"} - O
                    </div>
                </div>
            </div>
        </div>
        <WinnerModal isOpen={winnerModal} setIsOpen={setWinnerModal} winnerData={winnerData} />
    </>);
}
