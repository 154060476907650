import { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input';
import { ApiPost } from '../helpers/axiosFunctions';
import { errorToast, successToast } from '../helpers/commonFunctions';
import { useNavigate } from 'react-router-dom';

export default function OTPVerification() {
    const navigate = useNavigate()
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false)

    const inputOnChange = async (value) => {
        setOtp(value)
        if (value.length === 6) {
            setLoading(true)
            await ApiPost("/user/otp_verification", { email: localStorage.getItem("zero_kataa_email"), otp: Number(value) })
                .then((result) => {
                    successToast(result?.message)
                    localStorage.removeItem("zero_kataa_email")
                    navigate("/home")
                })
                .catch((error) => {
                    errorToast(error.message)
                })
                .finally(() => { setLoading(false) })
        }
    }


    const handleResend = async () => {
        setLoading(true)
        await ApiPost("/user/resend_otp", { email: localStorage.getItem("zero_kataa_email") })
            .then((result) => {
                successToast(result?.message)
                setOtp("")
            })
            .catch((error) => {
                errorToast(error.message)
            })
            .finally(() => { setLoading(false) })
    }

    useEffect(() => {
        if (!localStorage.getItem("zero_kataa_email")) {
            errorToast("email not found! Redirecting...")
            setTimeout(() => {
                navigate("/auth/login")
            }, 3000)
        }
    }, [])

    return (
        <div className=' w-full flex flex-col flex-center px-10'>
            <div className="heading text-center">
                <h1 className='text-5xl font-bold text-textYellow'>VERIFICATION</h1>
                <h4 className='text-lg font-semibold text-white/80 my-2'>Enter the 6-digit security code <br />and your email will be verified</h4>
            </div>
            <div className='flex flex-col gap-3 w-full '>
                <div className='text-white mt-5'>Verification Code</div>
                <OTPInput
                    value={otp}
                    onChange={inputOnChange}
                    numInputs={6}
                    renderSeparator={<span className='text-white'>-</span>}
                    inputStyle={{
                        backgroundColor: 'transparent',
                        padding: "7px",
                        border: "2px solid #ffb011",
                        borderRadius: "10px",
                        color: loading ? 'gray' : 'white',
                        width: "100%",
                        fontSize: "20px",
                    }}
                    containerStyle={{ display: 'flex', gap: '5px' }}
                    shouldAutoFocus
                    renderInput={(props) => <input {...props} disabled={loading} />}
                />
            </div>
            {loading ?
                <div className='mt-7 text-4xl text-textYellow'>
                    Loading...
                </div> :
                <p className='text-white/75 mt-7 w-full'>Didn't receive the code? <a className='text-textYellow cursor-pointer' onClick={handleResend}>Resend</a></p>}
        </div>
    )
}
