import { useEffect, useState } from 'react'
import { ApiGet } from '../helpers/axiosFunctions'
import { errorToast } from '../helpers/commonFunctions'
import moment from 'moment'
import { TransactionEntryType } from '../helpers/constants'

export default function TransactionPage() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    console.log('data', data)

    const fetchTransactionData = async () => {
        setLoading(true)
        await ApiGet("/user/transactions")
            .then((res) => {
                setData(res?.data)
            })
            .catch((error) => {
                errorToast(error?.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchTransactionData()
    }, [])

    return (<div className='w-full flex flex-col items-center'>
        <h1 className='text-textYellow text-3xl my-7'>Transactions</h1>
        {loading && <div className='text-textYellow text-4xl h-full flex items-center'><h1>Loading...</h1></div>}
        {!loading && <>
            <div className="overflow-x-auto w-full mb-7 border rounded-xl">
                <table className="w-full border bg-slate-500/10 border-gray-200 rounded-xl overflow-hidden shadow-md">
                    <thead>
                        <tr className="bg-textYellow text-left">
                            <th className="px-4 py-2 border-b w-[130px]">Date</th>
                            <th className="px-4 py-2 border-b">Amount</th>
                            <th className="px-4 py-2 border-b">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user) => {
                            let entrytypeColor = TransactionEntryType[user.entryType]
                            if (entrytypeColor === "Credited") {
                                entrytypeColor = "text-green-600"
                            } else if (entrytypeColor === "Debited") {
                                entrytypeColor = "text-red-600"
                            } else {
                                entrytypeColor = "text-textYellow"
                            }
                            return <tr key={user.id} className={"text-left " + entrytypeColor}>
                                <td className="px-4 py-2 border-b tracking-[2px]">{moment(user.createdAt).format("DD-MM-YYYY")}</td>
                                <td className="px-4 py-2 border-b tracking-[2px]">₹{user.amount}</td>
                                <td className="px-4 py-2 border-b tracking-[2px]">{TransactionEntryType[user.entryType] || "-"}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>}
    </div>)
}
