import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function StartPage() {
    const navigate = useNavigate()
    const handlePlayBtn = () => {
        if (localStorage.getItem('zero_kataa_user')) {
            navigate("/home")
        } else {
            navigate("/auth/login")
        }
    }
    return (<>
        <div className='flex flex-col flex-center w-full text-textYellow bg-[url(/public/media/images/background_1stPage.png)] bg-cover bg-center'>
            <img src="/media/images/tic_tac_toe_Logo.png" alt="zero_kataa_logo" />
            <h1 className=' text-5xl'>ZERO KATAA</h1>
            <button className='theme-btn px-12 mt-[50px]' onClick={handlePlayBtn}>PLAY</button>
            <h3 className='mt-5 text-xl'>TAP TO PLAY BUTTON</h3>
        </div>
    </>)
}
