export const calculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

// Easy AI (Random move)
export const easyAI = (squares) => {
  const emptyIndexes = squares
    .map((value, index) => (value === null ? index : null))
    .filter((val) => val !== null);
  return emptyIndexes[Math.floor(Math.random() * emptyIndexes.length)];
};

// Medium AI (Prioritize winning/blocking)
export const mediumAI = (squares, ai, player) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  // Check for winning move
  for (let line of lines) {
    const [a, b, c] = line;
    const values = [squares[a], squares[b], squares[c]];
    if (values.filter((v) => v === ai).length === 2 && values.includes(null)) {
      return line[values.indexOf(null)];
    }
  }

  // Check for blocking move
  for (let line of lines) {
    const [a, b, c] = line;
    const values = [squares[a], squares[b], squares[c]];
    if (values.filter((v) => v === player).length === 2 && values.includes(null)) {
      return line[values.indexOf(null)];
    }
  }

  return easyAI(squares); // Fallback to random
};

// Hard AI (Minimax algorithm)
export const hardAI = (squares, isMaximizing, ai, player) => {
  const winner = calculateWinner(squares);
  if (winner === ai) return 10;
  if (winner === player) return -10;
  if (!squares.includes(null)) return 0;

  const moves = [];
  for (let i = 0; i < squares.length; i++) {
    if (squares[i] === null) {
      const newSquares = [...squares];
      newSquares[i] = isMaximizing ? ai : player;
      moves.push({
        index: i,
        score: hardAI(newSquares, !isMaximizing, ai, player),
      });
    }
  }

  const bestMoves = moves.reduce((best, move) => {
    if (isMaximizing) {
      if (move.score > best[0].score) return [move];
      if (move.score === best[0].score) return [...best, move];
    } else {
      if (move.score < best[0].score) return [move];
      if (move.score === best[0].score) return [...best, move];
    }
    return best;
  }, [moves[0]]);

  const chosenMove = bestMoves[Math.floor(Math.random() * bestMoves.length)];

  return isMaximizing ? chosenMove.index : chosenMove.score;
};