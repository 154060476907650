import { Dialog, DialogPanel } from '@headlessui/react'
import { ApiPost } from '../../helpers/axiosFunctions'
import { errorToast } from '../../helpers/commonFunctions'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import { changeProfile_UserData } from '../../Redux/slices/profileSlice'
import axios from 'axios'
import { BaseURL } from '../../helpers/api-common'

export default function AddMoneyModal({ isOpen, setIsOpen }) {
    const dispatch = useDispatch()
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)


    const HandlePay = async () => {
        setLoading(true)
        await ApiPost("/user/payU", { amount: value })
            .then(async (res) => {
                const form = document.createElement('form');
                form.action = 'https://test.payu.in/_payment';
                form.method = 'POST';
                res.data.surl = BaseURL + "/user/checkPayment"
                res.data.furl = BaseURL + "/user/checkPayment"

                await Object.entries(res?.data).forEach(([key, value]) => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                    });

                document.body.appendChild(form);
                form.submit();
            })
            .catch((error) => {
                errorToast(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setValue("")
    }, [isOpen])

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={() => { setIsOpen(false) }}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(50%)] data-[closed]:opacity-0"
                        >
                            <h1 className="text-4xl text-center font-medium text-white">
                                Add Money
                            </h1>
                            <h1 className="text-2xl text-center font-medium text-textYellow text-shadow">
                                (Bonus Wallet)
                            </h1>
                            <div className=' bg-[url(/public/media/images/selectTournamentBG.png)] bg-contain bg-no bg-no-repeat h-[230px] mt-7 w-[200px] mx-auto text-center'>
                                <input
                                    type="text"
                                    className='mt-[95px] py-2 bg-transparent outline-none text-white text-2xl w-full text-center'
                                    value={value}
                                    onChange={({ target }) => { if (isNaN(target.value)) return; setValue(target.value) }}
                                    autoFocus={true}
                                />
                                <div className='flex flex-center mt-4'>
                                    <button className='theme-btn mx-auto px-7 !pt-1 !text-base' onClick={HandlePay} disabled={loading}>{loading ? "Loading..." : "BUY"}</button>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}