import React from 'react'
import AudioIcon from '../components/AudioIcon'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg';

export default function RulesPage() {
    return (<>
        <AudioIcon />
        <Link to="/home" className='absolute top-10 left-7 cursor-pointer'>
            <SVG src="/media/svg/backArrow.svg" className='h-7' />
        </Link>
        <div className='flex flex-col flex-center w-full px-10'>
            <h1 className='text-4xl text-textYellow'>GAME RULES</h1>
            <div className="flex items-center gap-7 mt-7">
                <div className='w-[100px]'>
                    <img src="/media/images/tic_tac_toe_Logo.png" alt="zeroKataaLgo" />
                </div>
                <div className='flex-1'>
                    <h3 className='text-2xl text-textYellow'>WIN</h3>
                    <desc className='text-white' >Enter the 6-digit security code and your email will be verified</desc>
                </div>
            </div>
            <div className="flex items-center gap-7 mt-7">
                <div className='w-[100px]'>
                    <img src="/media/images/tic_tac_toe_Logo.png" alt="zeroKataaLgo" />
                </div>
                <div className='flex-1'>
                    <h3 className='text-2xl text-textYellow'>DEFEAT</h3>
                    <desc className='text-white' >Enter the 6-digit security code and your email will be verified</desc>
                </div>
            </div>
            <div className="flex items-center gap-7 mt-7">
                <div className='w-[100px]'>
                    <img src="/media/images/tic_tac_toe_Logo.png" alt="zeroKataaLgo" />
                </div>
                <div className='flex-1'>
                    <h3 className='text-2xl text-textYellow'>DRAW</h3>
                    <desc className='text-white' >Enter the 6-digit security code and your email will be verified</desc>
                </div>
            </div>
        </div>
    </>
    )
}
