import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: {},
    callFetchData: true
};

const profileSlice = createSlice({
    name: "profileData",
    initialState,
    reducers: {
        changeProfile_UserData(state, { payload }) {
            state.userData = payload;
        },
        changeProfile_callFetchData(state, { payload }) {
            state.callFetchData = payload;
        },
    },
});

export const {
    changeProfile_UserData,
    changeProfile_callFetchData
} = profileSlice.actions;

export default profileSlice.reducer;