import AudioIcon from "../components/AudioIcon";

export default function LayoutWithoutNavbar({ children }) {
    return (<>
        <div className='bg-[radial-gradient(#2d2d2d,black)] font-daydreamer'>
            <div className="max-w-md min-h-screen mx-auto flex flex-col relative">
                <AudioIcon />
                <div className='flex-1 flex bg-[url(/public/media/images/background_image.png)] bg-cover bg-top'>
                    {children}
                </div>
            </div>
        </div>
    </>)
}
