import { useEffect, useState } from "react";
import { calculateWinner, easyAI, hardAI, mediumAI } from "../../helpers/boardFunctions";
import { useNavigate } from "react-router-dom";
import { infoToast } from "../../helpers/commonFunctions";
import { useSelector } from "react-redux";

const ThreeBoardAI = () => {
    const { difficulty: difficultyR } = useSelector(state => state.boardData)
    const navigate = useNavigate()
    const [squares, setSquares] = useState(Array(9).fill(null));
    const [xIsNext, setXIsNext] = useState(true);
    const [difficulty, setDifficulty] = useState("Easy"); // Default difficulty
    const aiPlayer = "O";
    const humanPlayer = "X";

    const winner = calculateWinner(squares);
    const isDraw = squares.every((square) => square !== null) && !winner;

    const handleClick = (index) => {
        if (winner || squares[index]) return;
        if (!xIsNext) return;
        const newSquares = [...squares];
        newSquares[index] = xIsNext ? "X" : "O";
        setSquares(newSquares);
        setXIsNext(() => !xIsNext);

        if (xIsNext) {
            setTimeout(() => makeAIMove(newSquares), 3000);
        }
    };

    const makeAIMove = (currentSquares) => {
        let move;
        if (difficulty === "Easy") {
            move = easyAI(currentSquares);
        } else if (difficulty === "Medium") {
            move = mediumAI(currentSquares, aiPlayer, humanPlayer);
        } else if (difficulty === "Hard") {
            move = hardAI(currentSquares, true, aiPlayer, humanPlayer);
        }

        if (move !== undefined) {
            const newSquares = [...currentSquares];
            newSquares[move] = aiPlayer;
            setSquares(newSquares);
            setXIsNext(true);
        }
    };

    const resetGame = () => {
        setSquares(Array(9).fill(null));
        setXIsNext(true);
    };

    useEffect(() => {
        if (winner || isDraw) {
            infoToast("Redirecting to Home...")
            setTimeout(() => {
                navigate("/home")
            }, 3000);
        }
    }, [winner, isDraw])

    useEffect(() => {
        setDifficulty(difficultyR)
    }, [difficultyR])

    return (
        <div className="tic-tac-toe">
            <div className='text-white'>Timer 10 Min <br /> 00.00.08</div>
            {xIsNext && (!winner && !isDraw) && <>
                <div className='text-2xl text-textYellow mt-7'>Your Move</div>
                <div className='border border-textYellow bg-primaryPurple text-white/75 rounded-full pt-1 px-3 w-fit mx-auto mb-10'>X X X</div>
            </>}
            {!xIsNext && (!winner && !isDraw) && <>
                <div className='text-2xl text-textYellow mt-7'>AI Move</div>
                <div className='border border-textYellow bg-primaryPurple text-white/75 rounded-full pt-1 px-3 w-fit mx-auto mb-10'>O O O</div>
            </>}
            <div className="board outline outline-[15px] border-[10px] border-primaryPurple outline-black shadow-inner shadow-black text-4xl rounded-sm">
                {squares.map((square, index) => (
                    <button
                        key={index}
                        className="square odd:bg-[#F6B349] even:bg-[#FB577A]"
                        onClick={() => handleClick(index)}
                    >
                        {(square && square === "X") && <img src="/media/svg/X_in_board.svg" width={50} height={50} />}
                        {(square && square === "O") && <img src="/media/svg/O_in_board.svg" width={50} height={50} />}
                    </button>
                ))}
            </div>
            {winner && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-green-500 left-1/2 -translate-x-1/2 -translate-y-1/2">Winner: {winner}</p>}
            {isDraw && !winner && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-textYellow left-1/2 -translate-x-1/2 -translate-y-1/2">It's a Draw!</p>}
            <div className="flex justify-center gap-20 mt-14">
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${(xIsNext && (!winner && !isDraw)) && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        You - X
                    </div>
                </div>
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${(!xIsNext && (!winner && !isDraw)) && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://static.vecteezy.com/system/resources/thumbnails/025/271/424/small/robot-chatbot-generative-ai-free-png.png"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        Game AI - O
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeBoardAI;
